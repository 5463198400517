import { Button, DatePicker, Form, Modal, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import useFetchPDF from "../../hooks/useFetchPDF";
import { useLovItemListforPOSQuery } from "../../features/inventorypurchase/inventorypurchaseService";
export const AdminSaleReportItems = () => {
  const [form] = Form.useForm();
  const [showPdfModal, setShowPdfModal] = useState();
  const itemSelectRef = useRef();
  const [isItemSelectOpen, setIsItemSelectOpen] = useState(false);
  const [reportData, getReport] = useFetchPDF();
  const { data: LovItemListforPOS } = useLovItemListforPOSQuery();

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && (e.key === "l" || e.key === "L")) {
        //|| e.key === "Enter"
        e.preventDefault();
        itemSelectRef.current.focus();
        setIsItemSelectOpen(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const onFinish = (values) => {
    //POS/AdminSaleReport/{fromdate}/{todate}/{customerid}/{itemid}/{paymode}
    getReport(
      `pos/AdminSaleReport/${moment(values?.fromdate).format(
        "DD-MMM-YYYY"
      )}/${moment(values?.todate).format("DD-MMM-YYYY")}/${
        values?.customerid || "ALL"
      }/${values?.itemid || "ALL"}/${values?.paymode || "ALL"}`
    );
    setShowPdfModal(true);
  };
  return (
    <div>
      <div>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            fromdate: moment(),
            todate: moment(),
          }}
        >
          <div className="grid sm:grid-cols-4 gap-1">
            <Form.Item
              name="fromdate"
              label="From Date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker format={"DD-MMM-YYYY"} />
            </Form.Item>
            <Form.Item
              name="todate"
              label="To Date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker format={"DD-MMM-YYYY"} />
            </Form.Item>
            <Form.Item
              name="itemid"
              label="Item Name Alt+L"
              className="sm:col-span-2"
            >
              <Select
                ref={itemSelectRef}
                open={isItemSelectOpen}
                onDropdownVisibleChange={(open) => setIsItemSelectOpen(open)}
                placeholder="Select Item Name"
                options={LovItemListforPOS?.data?.map((item) => ({
                  searchvalue: item.item_name,
                  value: item?.itemid,
                  label: item?.item_name,
                }))}
                showSearch
                filterOption={(input, option) => {
                  const inputLower = input.toLowerCase();
                  return (
                    option?.searchvalue?.toLowerCase()?.includes(inputLower) ||
                    option?.value
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(inputLower)
                  );
                }}
                allowClear
              />
            </Form.Item>
            <Form.Item label=" ">
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      <Modal
        title="Report"
        open={showPdfModal}
        onCancel={() => setShowPdfModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button key="1" onClick={() => setShowPdfModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <iframe
          width="100%"
          height="500px"
          src={reportData}
          title="Report"
        ></iframe>
      </Modal>
    </div>
  );
};
