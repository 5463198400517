import { Button, DatePicker, Form, Modal, Select } from "antd";
import React, { useState } from "react";
import moment from "moment";
import useFetchPDF from "../../hooks/useFetchPDF";
import { useLovInvManufactureQuery } from "../../features/inventoryConfigurators/inventoryConfiguratorsService";
export const AdminPurchaseReportCompany = () => {
  const [form] = Form.useForm();
  const [showPdfModal, setShowPdfModal] = useState();

  const [reportData, getReport] = useFetchPDF();
  const { data: lovInvManufacture } = useLovInvManufactureQuery();
  const onFinish = (values) => {
    getReport(
      `pos/AdminPurchaseReport/${moment(values?.fromdate).format(
        "DD-MMM-YYYY"
      )}/${moment(values?.todate).format("DD-MMM-YYYY")}/${
        values?.customerid || "ALL"
      }/${values?.itemid || "ALL"}/${values?.categoryid || "ALL"}/${
        values?.mafcid || "ALL"
      }/${values?.classid || "ALL"}/${values?.typeid || "ALL"}`
    );
    setShowPdfModal(true);
  };
  return (
    <div>
      <div>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            fromdate: moment(),
            todate: moment(),
          }}
        >
          <div className="grid sm:grid-cols-4 gap-1">
            <Form.Item
              name="fromdate"
              label="From Date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker format={"DD-MMM-YYYY"} />
            </Form.Item>
            <Form.Item
              name="todate"
              label="To Date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker format={"DD-MMM-YYYY"} />
            </Form.Item>
            <Form.Item
              name="mafcid"
              label="Company"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Select Company"
                options={lovInvManufacture?.data?.map((item) => ({
                  value: item?.code,
                  label: `${item?.descr}`,
                }))}
                showSearch
                filterOption={(input, option) =>
                  option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                }
              />
            </Form.Item>

            <Form.Item label=" ">
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      <Modal
        title="Report"
        open={showPdfModal}
        onCancel={() => setShowPdfModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button key="1" onClick={() => setShowPdfModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <iframe
          width="100%"
          height="500px"
          src={reportData}
          title="Report"
        ></iframe>
      </Modal>
    </div>
  );
};
