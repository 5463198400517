import { Button, Form, Input, Modal, Select, Table, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  useCreatePurchaseEntryMutation,
  // useDeleteposdetrecordMutation,
  useLovSupplierBalanceMutation,
  useLovItemListforPOSQuery,
  useLovVendrQuery,
  // useUpdatePosDetailRecordMutation,
  // useUpdPosmasterMutation,
} from "../inventorypurchaseService";
import useTableFilters from "../../../hooks/useTableFilter";
import useFetchPDF from "../../../hooks/useFetchPDF";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import openNotification from "../../../utils/notification";
import { PurchasePending } from "../modal/PurchasePending";
import { PurchaseFinishList } from "../modal/PurchaseFinishList";

export const PurchaseEntry = () => {
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const [buttonid, setButtonid] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState();
  const [showpendingModal, setShowpendingModal] = useState(false);
  const [showfinishModal, setShowfinishModal] = useState(false);
  const [reportData, getReport] = useFetchPDF();
  const { data: LovItemListforPOS } = useLovItemListforPOSQuery();
  const { data: lovVender } = useLovVendrQuery();
  useEffect(() => {
    const updatedTableData = tableData.map((item) => ({
      ...item,
      total: item.price * (item.qty || 1),
    }));
    let totalValue = updatedTableData.reduce(
      (acc, item) => acc + (item.total || 0),
      0
    );
    form.setFieldsValue({
      purchasetotalamt: totalValue,
      // finalamount: totalValue,
      // payableamount: totalValue,
    }); //, finalamount: totalValue

    setTableData(updatedTableData);
  }, [tableData, form]);
  const [createpurchaseentry, { data: masterdata, isSuccess: masterSuccess }] =
    useCreatePurchaseEntryMutation();
  const [savebalance, { data: getbalance }] = useLovSupplierBalanceMutation();
  const filter = useTableFilters();
  // useEffect(() => {
  //   if (getinvnum) {
  //     form.setFieldsValue({
  //       // code: getinvnum?.data[0].code || 0,
  //       // purchasetotalamt: getinvnum?.data[0].purchasetotalamt || 0,
  //       supplierbalance: getinvnum?.data[0].purchasediscamt || 0,
  //       // discount: getinvnum?.data[0].totalpaid || 0,
  //       // finalamount: getinvnum?.data[0].totalpaid || 0,
  //     });
  //   }
  // }, [getinvnum, form]);
  useEffect(() => {
    if (getbalance) {
      form.setFieldsValue({
        supplierbalance: getbalance?.data[0].balance || 0,
        cellno: getbalance?.data[0].cellno,
      });
    }
  }, [getbalance, form]);
  useEffect(() => {
    if (masterSuccess) {
      openNotification("success", "Created Successfully", 5);
      getReport(`pos/GetPurchaseEntryReport/${masterdata?.data.invnum}`);
      setShowPdfModal(true);
      setTableData([]);
      form.resetFields();
    }
  }, [masterSuccess]); //eslint-disable-line
  const columns = [
    {
      title: "Itemname",
      dataIndex: "item",
      ...filter("item"),
      sorter: (a, b) => a?.item?.localeCompare(b?.item),
    },
    {
      title: "Description",
      dataIndex: "item_name",
      ...filter("item_name"),
      sorter: (a, b) => a?.item_name?.localeCompare(b?.item_name),
    },

    {
      title: "Qty",
      dataIndex: "qty",
      render: (text, record) => (
        <Input
          ref={itemSelectRefqty}
          placeholder="Enter Qty"
          defaultValue={1}
          value={record.qty}
          onChange={(e) =>
            handleInputChange(record.itemid, "qty", e.target.value)
          }
          // className=""
        />
      ),
    },
    {
      title: "Price F5",
      dataIndex: "price",
      render: (text, record) => (
        <Input
          ref={priceInputRef}
          placeholder="Enter Price"
          defaultValue={1}
          value={record.price}
          onChange={(e) =>
            handleInputChange(record.itemid, "price", e.target.value)
          }
          className=" w-1/2"
        />
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      render: (_, record) => {
        const total = record.price * (record.qty || 1);
        return <span>{total.toFixed(2)}</span>;
      },
    },
    // {
    //   title: "Discount Price",
    //   dataIndex: "discountprice",
    // },
    {
      title: "Action(s)",
      key: "actions",
      render: (record) => (
        <div className="space-x-0.5">
          <Tooltip title="Delete" placement="left">
            <Button
              danger
              icon={<i className="fa fa-trash"></i>}
              onClick={() => handleDelete(record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  //add feature
  const itemSelectRef = useRef();
  const itemSelectRefqty = useRef();
  const [isItemSelectOpen, setIsItemSelectOpen] = useState(false);
  const itemSelectRefcustomer = useRef();
  const [isItemSelectOpencustomer, setIsItemSelectOpencustomer] =
    useState(false);
  // const [showchallanModal, setShowchallanModal] = useState(false);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && (e.key === "l" || e.key === "L")) {
        //|| e.key === "Enter"
        e.preventDefault();
        itemSelectRef.current.focus();
        setIsItemSelectOpen(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  useEffect(() => {
    itemSelectRef.current.focus();
  }, []);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && (e.key === "c" || e.key === "C")) {
        //|| e.key === "Enter"
        e.preventDefault();
        itemSelectRefcustomer.current.focus();
        setIsItemSelectOpencustomer(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const discountInputRef = useRef(null);
  const dispercentageInputRef = useRef(null);
  const priceInputRef = useRef(null);
  const payableamountInputRef = useRef(null);
  const supplierInputRef = useRef(null);
  const invbalanceInputRef = useRef(null);
  const newbalanceInputRef = useRef(null);
  const invnumInputRef = useRef(null);
  const savebuttonRef = useRef(null);
  const [discountsis, setDiscountsis] = useState(false);
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Check if F1 key is pressed
      if (e.key === "F1") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (discountInputRef.current) {
          discountInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F2") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (dispercentageInputRef.current) {
          dispercentageInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F3") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (supplierInputRef.current) {
          supplierInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F4") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (payableamountInputRef.current) {
          payableamountInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F5") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (priceInputRef.current) {
          priceInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F6") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (invbalanceInputRef.current) {
          invbalanceInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F7") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (newbalanceInputRef.current) {
          newbalanceInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F8") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (invnumInputRef.current) {
          invnumInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.altKey && (e.key === "s" || e.key === "S")) {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (savebuttonRef.current) {
          savebuttonRef.current.click(); // Focus on the discount input
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //
  const handleInputChange = (key, dataIndex, value) => {
    // Update the data array with the new value
    const newData = tableData.map((item) => {
      if (item.itemid === key) {
        // Ensure value is a number or null
        const parsedValue = value === "" ? null : parseInt(value, 10);
        return {
          ...item,
          [dataIndex]: parsedValue,
        };
      }
      return item;
    });

    setTableData(newData);
  };

  const onFinish = (values) => {
    if (buttonid === "savepurchaseentry") {
      const updateData = tableData.map((row) => ({
        itemid: row.itemid,
        itemname: row.item,
        quantity: row.qty || 1,
        purchaseprice: row.price,
        saleprice: row.purchaseprice,
        totalprice: row.price * (row.qty || 1),
        taxpct: "0",
        discountamount: "0",
      }));
      createpurchaseentry({
        // invtype:
        //   values?.paymode === "Cash"
        //     ? "C"
        //     : values?.paymode === "Credit"
        //     ? "Cr"
        //     : "",
        purchasetotalamt: values?.purchasetotalamt,
        purchasediscamt: values?.purchasediscamt || 0,
        taxamt: 0,
        totalpaid: values?.payableamount
          ? values?.payableamount
          : values?.purchasetotalamt,
        supplierbalance: values?.supplierbalance || 0,
        supplierid: values?.code,
        paymode: values?.paymode,
        status: "Y", //D for deliver
        data: updateData,
        message: "B",
      });
    } else if (buttonid === "savepurchasepending" && tableData?.length !== 0) {
      const updateData = tableData.map((row) => ({
        itemid: row.itemid,
        itemname: row.item,
        quantity: row.qty || 1,
        purchaseprice: row.price,
        saleprice: row.purchaseprice,
        totalprice: row.price * (row.qty || 1),
        taxpct: "0",
        discountamount: "0",
      }));
      createpurchaseentry({
        // invtype:
        //   values?.paymode === "Cash"
        //     ? "C"
        //     : values?.paymode === "Credit"
        //     ? "Cr"
        //     : "",
        purchasetotalamt: values?.purchasetotalamt,
        purchasediscamt: values?.purchasediscamt || 0,
        taxamt: 0,
        totalpaid: values?.payableamount,
        supplierbalance: values?.supplierbalance || 0,
        supplierid: values?.code,
        paymode: values?.paymode,
        status: "D", //D for deliver
        data: updateData,
        message: "B",
      });
    }
  };
  const handleDelete = (record) => {
    setTableData((prevTabledata) =>
      prevTabledata.filter((row) => row.itemid !== record.itemid)
    );
    setRowsToDelete((prevRowsToDelete) => [...prevRowsToDelete, record.itemid]);
  };
  useEffect(() => {
    if (rowsToDelete.length > 0) {
      const updatedData = tableData.filter(
        (row) => !rowsToDelete.includes(row.key)
      );
      setTableData(updatedData);
      setRowsToDelete([]);
    }
  }, [rowsToDelete, tableData]);
  //
  const [disprevious, setDisprevious] = useState(false);
  const cus = form.getFieldValue("code");
  useEffect(() => {
    if (cus === 1) {
      setDisprevious(true);
    }
  }, [cus]);
  const handlereport = () => {
    const invnum = form.getFieldValue("invnum");
    if (invnum) {
      getReport(`pos/GetPurchaseEntryReport/${invnum}`);
      setShowPdfModal(true);
    } else openNotification("error", "Please Enter Inv Number", 10);
  };
  return (
    <section>
      <h1 className=" text-center font-semibold text-base sm:text-lg p-2 bg-[#00b29c] text-white">
        Purchase Entry
      </h1>
      <div>
        <div>
          <Form
            form={form}
            id="purchaseentryid"
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
              purchasediscamt: 0,
              supplierbalance: 0,
              discountper: 0,
              paymode: "Cash",
            }}
          >
            <div className=" grid sm:grid-cols-4 grid-cols-1 gap-1">
              <Form.Item
                name="code"
                label="Supplier"
                className=" mb-0"
                rules={[
                  {
                    required:
                      buttonid === "savepurchaseentry" ||
                      buttonid === "savepurchasepending",
                  },
                ]}
              >
                <Select
                  ref={itemSelectRefcustomer}
                  open={isItemSelectOpencustomer}
                  onDropdownVisibleChange={(open) =>
                    setIsItemSelectOpencustomer(open)
                  }
                  placeholder="Select Supplier Name"
                  options={lovVender?.data?.map((item) => ({
                    value: item?.code,
                    label: `${item?.descr}`,
                  }))}
                  onChange={(value) => {
                    setIsItemSelectOpencustomer(false);
                    if (value) {
                      savebalance({ supplierid: value });
                    }
                    if (value !== 1) {
                      setDisprevious(false);
                    }
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                />
              </Form.Item>

              <Form.Item
                name="itemid"
                label="Item Name"
                className=" sm:col-span-2"
              >
                <Select
                  ref={itemSelectRef}
                  open={isItemSelectOpen}
                  onDropdownVisibleChange={(open) => setIsItemSelectOpen(open)}
                  placeholder="Select Item Name"
                  options={LovItemListforPOS?.data?.map((item) => ({
                    searchvalue: item.item_name,
                    value: item?.itemid,
                    label: (
                      <span className=" grid sm:grid-cols-5 justify-center items-center">
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {item?.itemid}
                        </span>
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                          className=" sm:col-span-2"
                        >
                          {item?.item_name}
                        </span>
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {item.price}
                        </span>
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {item.purchaseprice}
                        </span>
                      </span>
                    ),
                  }))}
                  dropdownRender={(menu) => (
                    <div>
                      {/* Header */}
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 2fr 1fr 1fr",
                          padding: "8px",
                          backgroundColor: "#47a686",
                          fontWeight: "bold",
                          borderBottom: "1px solid #ccc",
                        }}
                      >
                        <span>BarCode</span>
                        <span>Item Name</span>
                        <span>Price</span>
                        <span>Purchase Price</span>
                      </div>
                      {/* Dropdown Menu */}
                      {menu}
                    </div>
                  )}
                  onChange={(value) => {
                    setIsItemSelectOpen(false); // Close the dropdown after selecting
                    const updatedTableData = LovItemListforPOS?.data.find(
                      (item) => item.itemid === value
                    );
                    if (updatedTableData) {
                      const duplicate = tableData?.find(
                        (item) => item.itemid === updatedTableData?.itemid
                      );
                      if (!duplicate) {
                        setTableData((prevTableData) => [
                          ...prevTableData,
                          ...[updatedTableData],
                        ]);
                        setTimeout(() => {
                          if (itemSelectRefqty.current) {
                            itemSelectRefqty.current.focus();
                            itemSelectRefqty.current.select();
                          }
                        }, 100);
                      } else
                        openNotification(
                          "error",
                          "Item is Already in The Table",
                          10
                        );
                    }
                  }}
                  showSearch
                  filterOption={(input, option) => {
                    const inputLower = input.toLowerCase();
                    return (
                      option?.searchvalue
                        ?.toLowerCase()
                        ?.includes(inputLower) ||
                      option?.value
                        ?.toString()
                        ?.toLowerCase()
                        ?.includes(inputLower)
                    );
                  }}
                  allowClear
                />
              </Form.Item>
              <Form.Item name="cellno" label="Cell Number" className=" mb-0">
                <Input />
              </Form.Item>
              <div className="sm:col-span-3">
                <Form.Item className="  mb-0">
                  <Table
                    columns={columns}
                    dataSource={tableData}
                    size="small"
                    scroll={{
                      x: "fit-content",
                      y: tableData.length > 5 ? 250 : null,
                    }}
                    rowKey="itemid"
                  />
                </Form.Item>
                <div className=" grid  sm:grid-cols-6 grid-cols-1 gap-1 mt-1">
                  <Form.Item
                    name="invnum"
                    label="Invoice Number F8"
                    className=" mb-0"
                  >
                    <Input ref={invnumInputRef} />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      className="w-full"
                      onClick={() => {
                        handlereport();
                      }}
                    >
                      Get Report
                    </Button>
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => {
                      setButtonid("savepurchasepending");
                      setShowpendingModal(true);
                    }}
                  >
                    Pending
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowfinishModal(true);
                    }}
                  >
                    Finish List
                  </Button>
                  <Button
                    type="primary"
                    disabled
                    onClick={() => {
                      // getReport(`pos/DeliveryChalanRPT/${getinvinfo?.data[0].invnum}`);
                      // setShowchallanModal(true);
                    }}
                  >
                    Delivery Challan Report
                  </Button>
                  <div className=" flex gap-1">
                    <Tooltip title="Previous">
                      <Button
                        type="primary"
                        // onClick={handlePrevious}
                        // disabled={currentIndex < 0}
                      >
                        <FaArrowLeft />
                      </Button>
                    </Tooltip>

                    <Tooltip title="Next">
                      <Button
                        type="primary"
                        // onClick={handleNext}
                        // disabled={currentIndex === dataLength - 1}
                      >
                        <FaArrowRight />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="grid sm:grid-cols-4 grid-cols-1 gap-1 mb-0">
                <Form.Item
                  name="purchasetotalamt"
                  label="Purchase&nbsp;Total&nbsp;Amount"
                  className=" mb-0 sm:col-span-4"
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  name="purchasediscamt"
                  label="Pu Dis F1"
                  className=" mb-0"
                  rules={[
                    {
                      required:
                        buttonid === "savepurchaseentry" ||
                        buttonid === "savepurchasepending",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    ref={discountInputRef}
                    onBlur={(e) => {
                      const total = form.getFieldValue("purchasetotalamt");

                      let final = parseInt(total) - parseInt(e.target.value);
                      const dis =
                        (parseInt(e.target.value) / parseInt(total)) * 100;
                      form.setFieldsValue({
                        finalamount: final,
                        discountper: dis,
                        invbalance: final,
                        payableamount: 0,
                      });
                      setDiscountsis(true);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="discountper"
                  label="Dis % F2"
                  className=" mb-0"
                  rules={[
                    {
                      required:
                        buttonid === "savepurchaseentry" ||
                        buttonid === "savepurchasepending",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    ref={dispercentageInputRef}
                    disabled={discountsis}
                    onBlur={(e) => {
                      const total = form.getFieldValue("purchasetotalamt");

                      let final =
                        parseInt(total) -
                        (parseInt(e.target.value) / 100) * parseInt(total);
                      form.setFieldsValue({
                        finalamount: final,
                        invbalance: final,
                        payableamount: 0,
                        purchasediscamt:
                          (parseInt(e.target.value) / 100) * parseInt(total),
                        nettotal: final,
                      });
                    }}
                  />
                </Form.Item>
                {/* <Form.Item
                  name="finalamount"
                  label="Final Amount"
                  className=" mb-0"
                  // rules={[
                  //   {
                  //     required:
                  //       buttonid === "savepurchaseentry" ||
                  //       buttonid === "savepurchasepending",
                  //   },
                  // ]}
                >
                  <Input disabled />
                </Form.Item> */}
                <Form.Item name="nettotal" label="Net Total" className=" mb-0">
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  name="payableamount"
                  label="Paid F4"
                  className=" mb-0"
                  rules={[
                    {
                      required:
                        buttonid === "savepurchaseentry" ||
                        buttonid === "savepurchasepending",
                    },
                  ]}
                >
                  <Input ref={payableamountInputRef} readOnly />
                </Form.Item>
                <Form.Item
                  name="invbalance"
                  label="Inv blnce F6"
                  className=" mb-0"
                  rules={[
                    {
                      required:
                        buttonid === "savepurchaseentry" ||
                        buttonid === "savepurchasepending",
                    },
                  ]}
                >
                  <Input
                    ref={invbalanceInputRef}
                    readOnly
                    onBlur={(e) => {
                      const total = form.getFieldValue("purchasetotalamt");
                      const prebalance = form.getFieldValue("supplierbalance");
                      const inv = parseInt(total) - parseInt(e.target.value);
                      const newbalance = inv + prebalance;
                      form.setFieldsValue({
                        invbalance: parseInt(total) - parseInt(e.target.value),
                        newbalance: newbalance,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="supplierbalance"
                  label="Pre Blnce F3"
                  className=" mb-0"
                  rules={[
                    {
                      required:
                        buttonid === "savepurchaseentry" ||
                        buttonid === "savepurchasepending",
                    },
                  ]}
                >
                  <Input ref={supplierInputRef} readOnly />
                </Form.Item>
                <Form.Item
                  name="newbalance"
                  label="NewBlnc F7"
                  className=" mb-0"
                  rules={[
                    {
                      required:
                        buttonid === "savepurchaseentry" ||
                        buttonid === "savepurchasepending",
                    },
                  ]}
                >
                  <Input ref={newbalanceInputRef} readOnly />
                </Form.Item>
                {/* <Form.Item
                  name="payableamount"
                  label={
                    <span>
                      Payable&nbsp;Amount
                      <br />
                      F4
                    </span>
                  }
                  className=" mb-0"
                  // rules={[
                  //   {
                  //     required:
                  //       buttonid === "savepurchaseentry" ||
                  //       buttonid === "savepurchasepending",
                  //   },
                  // ]}
                >
                  <Input ref={payableamountInputRef} />
                </Form.Item> */}
                {/* <Form.Item
                  name="carriageamt"
                  label="Carriage"
                  className=" mb-0"
                  rules={[{ required: buttonid === "savepurchaseentry" || buttonid==="savepurchasepending" }]}
                >
                  <Input ref={dispercentageInputRef} />
                </Form.Item> */}
                <Form.Item
                  label="Mode"
                  name="paymode"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Mode"
                    options={[
                      { label: "Cash", value: "Cash" },
                      { label: "Credit", value: "Credit" },
                    ]}
                  />
                </Form.Item>
                <Form.Item label=" " className="mt-0 mb-0 sm:col-span-4">
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => setButtonid("savepurchaseentry")}
                    className=" w-full"
                    ref={savebuttonRef}
                  >
                    Create Purchase Entry
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>

        <Modal
          title="Pending List"
          open={showpendingModal}
          onCancel={() => setShowpendingModal(false)}
          centered
          width={1000}
          destroyOnClose
          footer={[
            <Button key="1" onClick={() => setShowpendingModal(false)}>
              Cancel
            </Button>,
          ]}
        >
          <PurchasePending
            tableDataMaster={tableData}
            setTableDataMaster={setTableData}
            itemSelectRefqty={itemSelectRefqty}
            handlecancel={() => setShowpendingModal(false)}
          />
        </Modal>
        <Modal
          title="Finish List"
          open={showfinishModal}
          onCancel={() => setShowfinishModal(false)}
          centered
          width={1000}
          destroyOnClose
          footer={[
            <Button key="1" onClick={() => setShowfinishModal(false)}>
              Cancel
            </Button>,
          ]}
        >
          <PurchaseFinishList />
        </Modal>
        <Modal
          title="Purchase Entry Report"
          open={showPdfModal}
          onCancel={() => setShowPdfModal(false)}
          centered
          width={1000}
          destroyOnClose
          footer={[
            <Button key="1" onClick={() => setShowPdfModal(false)}>
              Cancel
            </Button>,
          ]}
        >
          <iframe
            width="100%"
            height="500px"
            src={reportData}
            title="Purchase Entry Report"
          ></iframe>
        </Modal>
        {/* <Modal
          title="Delivery Challan Report"
          open={showchallanModal}
          onCancel={() => setShowchallanModal(false)}
          centered
          width={1000}
          destroyOnClose
          footer={[
            <Button key="1" onClick={() => setShowchallanModal(false)}>
              Cancel
            </Button>,
          ]}
        >
          <iframe
            width="100%"
            height="500px"
            src={reportData}
            title="Finance Report"
          ></iframe>
        </Modal> */}
      </div>
    </section>
  );
};
