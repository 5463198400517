import { Button, DatePicker, Form, Modal, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import useFetchPDF from "../../hooks/useFetchPDF";
import { useLovCustomerQuery } from "../../features/inventorypurchase/inventorypurchaseService";
export const AdminSaleReportCustomer = () => {
  const [form] = Form.useForm();
  const [showPdfModal, setShowPdfModal] = useState();
  const itemSelectRefcustomer = useRef();
  const [isItemSelectOpencustomer, setIsItemSelectOpencustomer] =
    useState(false);

  const [reportData, getReport] = useFetchPDF();
  const { data: lovCustomer } = useLovCustomerQuery();
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && (e.key === "c" || e.key === "C")) {
        //|| e.key === "Enter"
        e.preventDefault();
        itemSelectRefcustomer.current.focus();
        setIsItemSelectOpencustomer(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const onFinish = (values) => {
    //POS/AdminSaleReport/{fromdate}/{todate}/{customerid}/{itemid}/{paymode}
    getReport(
      `pos/AdminSaleReport/${moment(values?.fromdate).format(
        "DD-MMM-YYYY"
      )}/${moment(values?.todate).format("DD-MMM-YYYY")}/${
        values?.customerid || "ALL"
      }/${values?.itemid || "ALL"}/${values?.paymode || "ALL"}`
    );
    setShowPdfModal(true);
  };
  return (
    <div>
      <div>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            fromdate: moment(),
            todate: moment(),
          }}
        >
          <div className="grid sm:grid-cols-4 gap-1">
            <Form.Item
              name="fromdate"
              label="From Date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker format={"DD-MMM-YYYY"} />
            </Form.Item>
            <Form.Item
              name="todate"
              label="To Date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker format={"DD-MMM-YYYY"} />
            </Form.Item>
            <Form.Item
              name="customerid"
              label="Customer Alt+C"
              className=" mb-0"
              // rules={[
              //   {
              //     required:
              //       buttonid === "savepurchaseentry" ||
              //       buttonid === "savepurchasepending",
              //   },
              // ]}
            >
              <Select
                ref={itemSelectRefcustomer}
                open={isItemSelectOpencustomer}
                onDropdownVisibleChange={(open) =>
                  setIsItemSelectOpencustomer(open)
                }
                placeholder="Select Customer Name"
                options={lovCustomer?.data?.map((item) => ({
                  value: item?.customerid,
                  label: `${item?.name}`,
                }))}
                showSearch
                filterOption={(input, option) =>
                  option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                }
                allowClear
              />
            </Form.Item>

            <Form.Item label=" ">
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      <Modal
        title="Report"
        open={showPdfModal}
        onCancel={() => setShowPdfModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button key="1" onClick={() => setShowPdfModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <iframe
          width="100%"
          height="500px"
          src={reportData}
          title="Report"
        ></iframe>
      </Modal>
    </div>
  );
};
