import { AllAdminPurchaseReports } from "../allreport/AllAdminPurchaseReport";
import { AllAdminReports } from "../allreport/AllAdminSaleReport";

export const allreportRoutes = [
  {
    path: "adminsalereport",
    element: <AllAdminReports />,
  },
  {
    path: "adminpurchasereports",
    element: <AllAdminPurchaseReports />,
  },
];
