import { Tabs } from "antd";
import React from "react";
import { AdminSaleReportCustomer } from "./reports/AdminSaleReportCustomer";
import { AdminSaleReportItems } from "./reports/AdminSaleReportCustomerItems";
import { AdminSaleReportPaymode } from "./reports/AdminSaleReportCustomerPaymode";

export const AllAdminReports = () => {
  return (
    <section className="border border-gainsboro">
      <div
        className="flex items-center justify-between px-5 py-3"
        style={{ backgroundColor: "#00b29c" }}
      >
        <h1 className="font-semibold text-base sm:text-lg text-white">
          Admin Sale Report
        </h1>
      </div>
      <div className="p-5">
        <Tabs
          items={[
            {
              label: "Customer",
              key: "1",
              children: <AdminSaleReportCustomer />,
            },
            { label: "Items", key: "2", children: <AdminSaleReportItems /> },
            {
              label: "Paymode",
              key: "3",
              children: <AdminSaleReportPaymode />,
            },
          ]}
        />
      </div>
    </section>
  );
};
