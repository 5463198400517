import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Table,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  useCreateTrasactionhistoryMutation,
  useGetBalanceCustSupplierBaseMutation,
  useGetBalanceMutation,
  useLovCustomerQuery,
  useLovSupplierBalanceMutation,
  useLovVendrQuery,
} from "../inventorypurchaseService";
import moment from "moment";
import useFetchPDF from "../../../hooks/useFetchPDF";
import openNotification from "../../../utils/notification";
export const PaymentAccount = () => {
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [tableDataDetail, setTableDataDetail] = useState([]);
  const [buttonid, setButtonid] = useState(null);
  const [nameValue, setNameValue] = useState("S");
  const [showPdfModal, setShowPdfModal] = useState();
  const [reportData, getReport] = useFetchPDF();
  const { data: lovVender } = useLovVendrQuery();
  const { data: lovCustomer } = useLovCustomerQuery();
  const [savebalnce, { data: getbalance, isError: balnceError }] =
    useGetBalanceMutation();
  const [
    savebalnceSupplierbase,
    { data: getbalanceSupplierbase, isError: balancedetailError },
  ] = useGetBalanceCustSupplierBaseMutation();
  const [
    createbalance,
    { data: getcreatedbalance, isSuccess: createdbalanceSuccess },
  ] = useCreateTrasactionhistoryMutation();
  const [saveprebalance, { data: getprebalance }] =
    useLovSupplierBalanceMutation();
  useEffect(() => {
    if (getbalance) {
      setTableData(getbalance?.data);
      setTableDataDetail(getbalance?.data);
    }
  }, [getbalance]);
  useEffect(() => {
    if (getbalanceSupplierbase) {
      setTableData(getbalanceSupplierbase?.data);
      setTableDataDetail(getbalanceSupplierbase?.data);
    }
  }, [getbalanceSupplierbase]);
  useEffect(() => {
    if (balnceError) {
      setTableData([]);
      setTableDataDetail([]);
    }
  }, [balnceError]);
  useEffect(() => {
    if (balancedetailError) {
      setTableData([]);
      setTableDataDetail([]);
    }
  }, [balancedetailError]);
  useEffect(() => {
    if (createdbalanceSuccess) {
      openNotification("success", "Successfully Created", 5);
      getReport(
        `pos/GetBalanceReport/${getcreatedbalance?.data.transactionId}/${getcreatedbalance?.data.type}`
      );
      setShowPdfModal(true);
      form.resetFields();
    }
  }, [createdbalanceSuccess]); //eslint-disable-line
  const handlereport = () => {
    const type = form.getFieldValue("type");
    const transactionId = form.getFieldValue("transactionId");
    if (type && transactionId) {
      getReport(`pos/GetBalanceReport/${transactionId}/${type}`);
      setShowPdfModal(true);
    } else openNotification("error", "Please Enter Inv Number", 10);
  };
  const columns = [
    {
      title: "Id",
      dataIndex: "transactionid",
    },
    {
      title: "Date",
      dataIndex: "transactiondate",
      render: (_, { transactiondate }) =>
        moment(transactiondate).format("DD-MMM-YYYY"),
    },
    {
      title: "Rec Pay",
      dataIndex: "recpay",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "remarks",
    },
    {
      title: "Amount",
      dataIndex: "paidamount",
    },
    {
      title: "Dis_Per",
      dataIndex: "dis_per",
    },
    {
      title: "Dis_Rs",
      dataIndex: "dis_rs",
    },
    {
      title: "Check Date",
      dataIndex: "check",
    },
  ];
  const columnsdetail = [
    {
      title: "Date",
      dataIndex: "transactiondate",
      render: (_, { transactiondate }) =>
        moment(transactiondate).format("DD-MMM-YYYY"),
    },
    {
      title: "No",
      dataIndex: "no",
    },
    {
      title: "Detail",
      dataIndex: "detail",
    },
    {
      title: "Debit",
      dataIndex: "debit",
    },
    {
      title: "Credit",
      dataIndex: "credit",
    },
    {
      title: "Balance",
      dataIndex: "remainbalance",
    },
  ];
  const itemSelectRefcustomer = useRef();
  const [isItemSelectOpencustomer, setIsItemSelectOpencustomer] =
    useState(false);
  const supplierRef = useRef();
  const [isSupplierOpen, setIsSupplierOpen] = useState(false);
  const savebuttonRef = useRef(null);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && (e.key === "c" || e.key === "C")) {
        //|| e.key === "Enter"
        e.preventDefault();
        itemSelectRefcustomer.current.focus();
        setIsItemSelectOpencustomer(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && (e.key === "c" || e.key === "C")) {
        //|| e.key === "Enter"
        e.preventDefault();
        supplierRef.current.focus();
        setIsSupplierOpen(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  useEffect(() => {
    supplierRef.current.focus();
  }, []);
  useEffect(() => {
    const type = form.getFieldValue("type");
    if (type === "S") {
      savebalnce({
        fromdate: moment().format("DD-MMM-YYYY"),
        todate: moment().format("DD-MMM-YYYY"),
        type: type,
      });
    } else if (type === "C") {
      savebalnce({
        fromdate: moment().format("DD-MMM-YYYY"),
        todate: moment().format("DD-MMM-YYYY"),
        type: type,
      });
    }
  }, []); //eslint-disable-line
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Alt + S to save
      if (e.altKey && (e.key === "s" || e.key === "S")) {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (savebuttonRef.current) {
          savebuttonRef.current.click(); // Focus on the discount input
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  useEffect(() => {
    if (getprebalance) {
      form.setFieldsValue({
        prebalance:
          getprebalance?.data[0].balance < 0
            ? getprebalance?.data[0].balance
            : -getprebalance?.data[0].balance || 0,
        contact: getprebalance?.data[0].cellno,
      });
    }
  }, [getprebalance, form]);
  const onFinish = (values) => {
    if (buttonid === "search") {
      if (values?.name) {
        savebalnceSupplierbase({
          fromdate: moment(values?.fromdate).format("DD-MMM-YYYY"),
          todate: moment(values?.todate).format("DD-MMM-YYYY"),
          type: values?.type,
          custvendorid: values?.name,
        });
      } else {
        savebalnce({
          fromdate: moment(values?.fromdate).format("DD-MMM-YYYY"),
          todate: moment(values?.todate).format("DD-MMM-YYYY"),
          type: values?.type,
        });
      }
    }
    if (buttonid === "createbalance") {
      createbalance({
        vendorid: values?.name,
        amount: values?.amount,
        balanceAfterTransaction: values?.newbalance,
        description: values?.description,
        type: values?.type,
      });
    }
  };
  return (
    <section>
      <h1 className=" text-center font-semibold text-base sm:text-lg p-2 bg-[#00b29c] text-white">
        Payment
      </h1>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ type: "S", discountrs: 0, discountper: 0 }}
        layout="vertical"
      >
        <div className=" grid sm:grid-cols-2 gap-1">
          <div className=" grid sm:grid-cols-4 gap-1">
            <Form.Item name="type" label=" " className="mb-0">
              <Radio.Group
                onChange={(e) => {
                  console.log("value", e.target.value);
                  if (e.target.value === "S") {
                    form.setFieldsValue({ paidtype: "P" });
                    setNameValue("S");
                    savebalnce({
                      fromdate: moment().format("DD-MMM-YYYY"),
                      todate: moment().format("DD-MMM-YYYY"),
                      type: e.target.value,
                    });
                  } else if (e.target.value === "N") {
                    form.setFieldsValue({ paidtype: "R" });
                    setNameValue("C");
                    savebalnce({
                      fromdate: moment().format("DD-MMM-YYYY"),
                      todate: moment().format("DD-MMM-YYYY"),
                      type: e.target.value,
                    });
                  }
                }}
              >
                <Radio value="S">Supplier</Radio>
                <Radio value="N">Customer</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="paidtype" label=" " className="mb-0">
              <Radio.Group readOnly>
                <Radio value="P">Payment</Radio>
                <Radio value="R">Receiving</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item name="paymentid" label="Payment ID" className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="date" label="Date" className="mb-0">
              <DatePicker format={"DD-MMM-YYYY"} />
            </Form.Item>
            <Form.Item
              name="name"
              label="Name"
              className="mb-0 sm:col-span-2"
              rules={[{ required: buttonid === "createbalance" }]}
            >
              {nameValue === "S" ? (
                <Select
                  placeholder="Select Name"
                  ref={supplierRef}
                  open={isSupplierOpen}
                  onDropdownVisibleChange={(open) => setIsSupplierOpen(open)}
                  options={lovVender?.data?.map((item) => ({
                    value: item?.code,
                    label: `${item?.descr}`,
                  }))}
                  onChange={(value) => {
                    setIsItemSelectOpencustomer(false);
                    if (value) {
                      saveprebalance({ supplierid: value });
                      savebalnceSupplierbase({
                        fromdate: moment().format("DD-MMM-YYYY"),
                        todate: moment().format("DD-MMM-YYYY"),
                        type: "S",
                        custvendorid: value,
                      });
                    }
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                />
              ) : nameValue === "C" ? (
                <Select
                  ref={itemSelectRefcustomer}
                  open={isItemSelectOpencustomer}
                  onDropdownVisibleChange={(open) =>
                    setIsItemSelectOpencustomer(open)
                  }
                  placeholder="Select Customer Name"
                  options={lovCustomer?.data?.map((item) => ({
                    value: item?.customerid,
                    label: `${item?.name}`,
                  }))}
                  onChange={(value) => {
                    setIsItemSelectOpencustomer(false);
                    if (value) {
                      // saveprebalance({ supplierid: value });
                      savebalnceSupplierbase({
                        fromdate: moment().format("DD-MMM-YYYY"),
                        todate: moment().format("DD-MMM-YYYY"),
                        type: "C",
                        custvendorid: value,
                      });
                    }
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                />
              ) : (
                ""
              )}
            </Form.Item>
            {/* <Form.Item name="contact" label="Contact" className="mb-0">
              <Input readOnly />
            </Form.Item>
            <Form.Item name="address" label="Address" className="mb-0">
              <Input readOnly />
            </Form.Item> */}
            <Form.Item name="prebalance" label="Pre Balance" className="mb-0">
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name="amount"
              label="Amount"
              className="mb-0"
              rules={[{ required: buttonid === "createbalance" }]}
            >
              <Input
                onBlur={(e) => {
                  const total = form.getFieldValue("prebalance");
                  form.setFieldsValue({
                    newbalance: parseInt(total) + parseInt(e.target.value),
                  });
                }}
              />
            </Form.Item>
            <Form.Item name="discountrs" label="Discount Rs" className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="discountper" label="Discount %" className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="newbalance" label="New Balance" className="mb-0">
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              className="mb-0 "
              rules={[{ required: buttonid === "createbalance" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Mode"
              name="paymode"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
              className="mb-0"
            >
              <Select
                placeholder="Select Mode"
                options={[
                  { label: "Cash", value: "Cash" },
                  { label: "Credit", value: "Credit" },
                ]}
              />
            </Form.Item>
            <Form.Item name="balance" label="Balance" className="mb-0">
              <Input />
            </Form.Item>
          </div>
          <div>
            <div className=" grid sm:grid-cols-4 gap-1 mb-0 pb-0">
              <Form.Item name="fromdate" label="From Date" className="mb-0">
                <DatePicker format={"DD-MMM-YYYY"} />
              </Form.Item>
              <Form.Item name="todate" label="To Date" className="mb-0">
                <DatePicker format={"DD-MMM-YYYY"} />
              </Form.Item>
              <Form.Item label=" " className="mb-0">
                <Button
                  className=" w-full"
                  type="primary"
                  onClick={() => setButtonid("search")}
                  htmlType="submit"
                >
                  Search
                </Button>
              </Form.Item>
              <Form.Item label=" " className="mb-0">
                <Button
                  className=" w-full"
                  type="primary"
                  onClick={() => setButtonid("printbtn")}
                  htmlType="submit"
                >
                  Print
                </Button>
              </Form.Item>

              <Form.Item className=" mt-1 sm:col-span-4 mb-0">
                <Table
                  columns={columns}
                  dataSource={tableData}
                  scroll={{
                    x: "fit-content",
                    y: tableData.length > 0 ? 90 : null,
                  }}
                  size="small"
                  className="mt-0 pt-0"
                />
              </Form.Item>
              <div className=" sm:col-span-4 flex  justify-center items-center gap-1 mb-0">
                <Form.Item className="mb-0">
                  <Button
                    className=" w-full"
                    type="primary"
                    onClick={() => handlereport()}
                    // htmlType="submit"
                  >
                    Print
                  </Button>
                </Form.Item>

                <Form.Item name="transactionId" className="mb-0">
                  <Input />
                </Form.Item>
              </div>
              <Form.Item className=" mt-1 sm:col-span-4 mb-0">
                <Table
                  columns={columnsdetail}
                  dataSource={tableDataDetail}
                  scroll={{
                    x: "fit-content",
                    y: tableData.length > 0 ? 80 : null,
                  }}
                  size="small"
                  className="mt-0 pt-0"
                />
              </Form.Item>
              <Form.Item className="mb-0">
                <Button
                  className=" w-full"
                  type="primary"
                  onClick={() => setButtonid("createbalance")}
                  htmlType="submit"
                  ref={savebuttonRef}
                >
                  Create Balance
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
      <Modal
        title="Balance Report"
        open={showPdfModal}
        onCancel={() => setShowPdfModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button key="1" onClick={() => setShowPdfModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <iframe
          width="100%"
          height="500px"
          src={reportData}
          title="Balance Report"
        ></iframe>
      </Modal>
    </section>
  );
};
