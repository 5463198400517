import { Tabs } from "antd";
import React from "react";
import { AdminPurchaseReportSupplier } from "./reports/AdminPurchaseReportSupplier";
import { AdminPurchaseReportItems } from "./reports/AdminPurchaseReportCustomerItems";
import { AdminPurchaseReportCompany } from "./reports/AdminPurchaseReportComapny";
import { AdminPurchaseReportClass } from "./reports/AdminPurchaseReportClass";
import { AdminPurchaseReportType } from "./reports/AdminPurchaseReportType";
export const AllAdminPurchaseReports = () => {
  return (
    <section className="border border-gainsboro">
      <div
        className="flex items-center justify-between px-5 py-3"
        style={{ backgroundColor: "#00b29c" }}
      >
        <h1 className="font-semibold text-base sm:text-lg text-white">
          Admin Purchase Report
        </h1>
      </div>
      <div className="p-5">
        <Tabs
          items={[
            {
              label: "Customer",
              key: "1",
              children: <AdminPurchaseReportSupplier />,
            },
            {
              label: "Items",
              key: "2",
              children: <AdminPurchaseReportItems />,
            },
            {
              label: "Company",
              key: "3",
              children: <AdminPurchaseReportCompany />,
            },
            {
              label: "Class",
              key: "4",
              children: <AdminPurchaseReportClass />,
            },
            {
              label: "Type",
              key: "5",
              children: <AdminPurchaseReportType />,
            },
          ]}
        />
      </div>
    </section>
  );
};
