import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "../authService";
import { loginSuccess } from "../authSlice";
import { useEffect } from "react";

const LoginUsers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginUser, { data }] = useLoginMutation();
  //first time only

  useEffect(() => {
    // First-time API call with static values
    loginUser({
      username: "userproducts",
      password: "Pakistan12@12",
      message: "E",
    });
  }, []); //eslint-disable-line

  useEffect(() => {
    if (data) {
      dispatch(loginSuccess(data));
      navigate("/userproducts");
    }
  }, [data]); // eslint-disable-line

  return;
};

export default LoginUsers;
