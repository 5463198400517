import React, { useEffect, useRef, useState } from "react";
import {
  useCreateReturnEntryMutation,
  useLovItemListforPOSQuery,
  useLovSupplierBalanceMutation,
  useLovVendrQuery,
} from "../inventorypurchaseService";
import { Button, Form, Input, Modal, Select, Table, Tooltip } from "antd";
import openNotification from "../../../utils/notification";
import useTableFilters from "../../../hooks/useTableFilter";

import { useNavigate } from "react-router-dom";
import useFetchPDF from "../../../hooks/useFetchPDF";
import { PurchaseReturnFinishList } from "../modal/PurchaseReturnFinish";
export const PurchaseReturn = () => {
  const [form] = Form.useForm();
  const filter = useTableFilters();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [showPdfModal, setShowPdfModal] = useState();
  const [reportData, getReport] = useFetchPDF();
  const itemSelectRefcustomer = useRef();
  const itemSelectRef = useRef();
  const itemSelectRefqty = useRef();
  const [isItemSelectOpencustomer, setIsItemSelectOpencustomer] =
    useState(false);
  const [isItemSelectOpen, setIsItemSelectOpen] = useState(false);
  const [showfinishModal, setShowfinishModal] = useState(false);
  const { data: lovVender } = useLovVendrQuery();
  const { data: LovItemListforPOS } = useLovItemListforPOSQuery();
  const [savebalance, { data: getbalance }] = useLovSupplierBalanceMutation();
  const [createpurchasereturn, { data: masterdata, isSuccess: createSuccess }] =
    useCreateReturnEntryMutation();
  useEffect(() => {
    if (getbalance) {
      form.setFieldsValue({
        previousbalance: -getbalance?.data[0].balance || 0,
        // cellno: getbalance?.data[0].cellno,
      });
    }
  }, [getbalance, form]);
  useEffect(() => {
    if (createSuccess) {
      openNotification("success", "Successfully Created", 5);
      getReport(`pos/GetPurchaseReturnReport/${masterdata?.data.invnum}`);
      setShowPdfModal(true);
      setTableData([]);
      form.resetFields();
    }
  }, [createSuccess]); //eslint-disable-line
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && (e.key === "c" || e.key === "C")) {
        //|| e.key === "Enter"
        e.preventDefault();
        itemSelectRefcustomer.current.focus();
        setIsItemSelectOpencustomer(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const priceInputRef = useRef(null);
  const discountInputRef = useRef(null);
  const paidInputRef = useRef(null);
  const invbalanceInputRef = useRef(null);
  const prebalanceInputRef = useRef(null);
  const newbalanceInputRef = useRef(null);
  const invnumInputRef = useRef(null);
  const remarksInputRef = useRef(null);
  const savebuttonRef = useRef(null);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "F1") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (discountInputRef.current) {
          discountInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F2") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (paidInputRef.current) {
          paidInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F3") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (invbalanceInputRef.current) {
          invbalanceInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F4") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (prebalanceInputRef.current) {
          prebalanceInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F5") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (newbalanceInputRef.current) {
          newbalanceInputRef.current.focus(); // Focus on the discount input
        }
      }
      //
      if (e.key === "F6") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (priceInputRef.current) {
          priceInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F7") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (invnumInputRef.current) {
          invnumInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.altKey && (e.key === "s" || e.key === "S")) {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (savebuttonRef.current) {
          savebuttonRef.current.click(); // Focus on the discount input
        }
      }
      if (e.altKey && (e.key === "r" || e.key === "R")) {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (remarksInputRef.current) {
          remarksInputRef.current.focus(); // Focus on the discount input
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && (e.key === "l" || e.key === "L")) {
        //|| e.key === "Enter"
        e.preventDefault();
        itemSelectRef.current.focus();
        setIsItemSelectOpen(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  useEffect(() => {
    itemSelectRef.current.focus();
  }, []);
  const handleInputChange = (key, dataIndex, value) => {
    // Update the data array with the new value
    const newData = tableData.map((item) => {
      if (item.itemid === key) {
        // Ensure value is a number or null
        const parsedValue = value === "" ? null : parseInt(value, 10);
        return {
          ...item,
          [dataIndex]: parsedValue,
        };
      }
      return item;
    });

    setTableData(newData);
  };
  useEffect(() => {
    const updatedTableData = tableData.map((item) => ({
      ...item,
      total: item.price * (item.qty || 1),
    }));
    const totalValue = updatedTableData.reduce(
      (acc, item) => acc + (item.total || 0),
      0
    );
    form.setFieldsValue({ totalamount: totalValue }); //, finalamount: totalValue
    setTableData(updatedTableData);
  }, [tableData, form]);

  const columns = [
    {
      title: "Itemname",
      dataIndex: "item",
      ...filter("item"),
      sorter: (a, b) => a?.item?.localeCompare(b?.item),
    },
    {
      title: "Description",
      dataIndex: "item_name",
      ...filter("item_name"),
      sorter: (a, b) => a?.item_name?.localeCompare(b?.item_name),
    },

    {
      title: "Qty",
      dataIndex: "qty",
      render: (text, record) => (
        <Input
          ref={itemSelectRefqty}
          placeholder="Enter Qty"
          defaultValue={1}
          value={record.qty}
          onChange={(e) =>
            handleInputChange(record.itemid, "qty", e.target.value)
          }
          // className=""
        />
      ),
    },
    {
      title: "Price F6",
      dataIndex: "price",
      render: (text, record) => (
        <Input
          ref={priceInputRef}
          placeholder="Enter Price"
          defaultValue={1}
          value={record.price}
          onChange={(e) =>
            handleInputChange(record.itemid, "price", e.target.value)
          }
          className=" w-1/2"
        />
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      render: (_, record) => {
        const total = record.price * (record.qty || 1);
        return <span>{total.toFixed(2)}</span>;
      },
    },
    // {
    //   title: "Discount Price",
    //   dataIndex: "discountprice",
    // },
  ];
  const handlereport = () => {
    const invnum = form.getFieldValue("invnum");
    if (invnum) {
      getReport(`pos/GetPurchaseReturnReport/${invnum}`);
      setShowPdfModal(true);
    } else openNotification("error", "Please Enter Inv Number", 10);
  };
  const onFinish = (values) => {
    const updateData = tableData.map((row) => ({
      itemid: row.itemid,
      itemname: row.item,
      quantity: row.qty || 1,
      price: row.price,
      totalprice: row.price * (row.qty || 1),
    }));

    createpurchasereturn({
      vendorid: values?.code,
      supplierbalance: values?.supplierbalance || 0,
      totalinvamt: values?.totalinvamt,
      adjustedcamt: values?.adjustedcamt || 0,
      returnedamnt: values?.paid,
      balanceAfterTransaction:
        parseInt(values?.previousbalance) + parseInt(values?.paid) || 0,
      status: "Y",
      description: values?.description || "",
      data: updateData,
      message: "B",
    });
  };

  return (
    <section>
      <div className=" grid justify-end items-end mb-1">
        <Button
          type="primary"
          onClick={() => {
            navigate("/purchaseentry");
          }}
        >
          Purchase Entry
        </Button>
      </div>
      <h1 className=" text-center font-semibold text-base sm:text-lg p-2 bg-[#00b29c] text-white">
        Purchase Return
      </h1>
      <div>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            adjustedcamt: 0,
            nettotal: 0,
            paid: 0,
            totalinvamt: 0,
            newbalance: 0,
            previousbalance: 0,
          }}
        >
          <div className=" grid sm:grid-cols-4 grid-cols-1 gap-1">
            <Form.Item
              name="code"
              label="Supplier Alt+C"
              className=" mb-0"
              // rules={[
              //   {
              //     required:
              //       buttonid === "savepurchaseentry" ||
              //       buttonid === "savepurchasepending",
              //   },
              // ]}
            >
              <Select
                ref={itemSelectRefcustomer}
                open={isItemSelectOpencustomer}
                onDropdownVisibleChange={(open) =>
                  setIsItemSelectOpencustomer(open)
                }
                placeholder="Select Supplier Name"
                options={lovVender?.data?.map((item) => ({
                  value: item?.code,
                  label: `${item?.descr}`,
                }))}
                onChange={(value) => {
                  setIsItemSelectOpencustomer(false);
                  if (value) {
                    savebalance({ supplierid: value });
                  }
                }}
                showSearch
                filterOption={(input, option) =>
                  option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item
              name="itemid"
              label="Item Name Alt+L"
              className="sm:col-span-2"
            >
              <Select
                ref={itemSelectRef}
                open={isItemSelectOpen}
                onDropdownVisibleChange={(open) => setIsItemSelectOpen(open)}
                placeholder="Select Item Name"
                options={LovItemListforPOS?.data?.map((item) => ({
                  searchvalue: item.item_name,
                  value: item?.itemid,
                  label: (
                    <span className=" grid sm:grid-cols-5 justify-center items-center">
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {item?.itemid}
                      </span>
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                        className=" sm:col-span-2"
                      >
                        {item?.item_name}
                      </span>
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {item.price}
                      </span>
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {item.purchaseprice}
                      </span>
                    </span>
                  ),
                }))}
                dropdownRender={(menu) => (
                  <div>
                    {/* Header */}
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr 1fr 1fr",
                        padding: "8px",
                        backgroundColor: "#47a686",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                      }}
                    >
                      <span>BarCode</span>
                      <span>Item Name</span>
                      <span>Price</span>
                      <span>Purchase Price</span>
                    </div>
                    {/* Dropdown Menu */}
                    {menu}
                  </div>
                )}
                onChange={(value) => {
                  setIsItemSelectOpen(false); // Close the dropdown after selecting
                  const updatedTableData = LovItemListforPOS?.data.find(
                    (item) => item.itemid === value
                  );
                  if (updatedTableData) {
                    const duplicate = tableData?.find(
                      (item) => item.itemid === updatedTableData?.itemid
                    );
                    if (!duplicate) {
                      setTableData((prevTableData) => [
                        ...prevTableData,
                        ...[updatedTableData],
                      ]);
                      setTimeout(() => {
                        if (itemSelectRefqty.current) {
                          itemSelectRefqty.current.focus();
                          itemSelectRefqty.current.select();
                        }
                      }, 100);
                    } else
                      openNotification(
                        "error",
                        "Item is Already in The Table",
                        10
                      );
                  }
                }}
                showSearch
                filterOption={(input, option) => {
                  const inputLower = input.toLowerCase();
                  return (
                    option?.searchvalue?.toLowerCase()?.includes(inputLower) ||
                    option?.value
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(inputLower)
                  );
                }}
                allowClear
              />
            </Form.Item>

            <Form.Item
              name="description"
              label="Remarks Alt+R"
              className=" mb-0"
            >
              <Input ref={remarksInputRef} />
            </Form.Item>
            <Form.Item className=" sm:col-span-3">
              <Table
                size="small"
                columns={columns}
                dataSource={tableData}
                scroll={{
                  x: "fit-content",
                  y: tableData.length > 5 ? 250 : null,
                }}
              />
            </Form.Item>
            <div className="grid sm:grid-cols-2 gap-1">
              <Form.Item
                name="totalamount"
                label="Total Amount"
                className=" mb-0"
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item
                name="adjustedcamt"
                label="Discount Amount F1"
                className=" mb-0"
              >
                <Input
                  ref={discountInputRef}
                  onBlur={(e) => {
                    const total = form.getFieldValue("totalamount");
                    form.setFieldsValue({
                      paid: parseInt(total) + parseInt(e.target.value),
                      nettotal: parseInt(total) + parseInt(e.target.value),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item name="nettotal" label="Net Total" className=" mb-0">
                <Input readOnly />
              </Form.Item>
              <Form.Item name="paid" label="Paid F2" className=" mb-0">
                <Input
                  ref={paidInputRef}
                  onBlur={(e) => {
                    const total = form.getFieldValue("totalamount");
                    form.setFieldsValue({
                      totalinvamt: parseInt(total) - parseInt(e.target.value),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                name="totalinvamt"
                label="Inv Balance F3"
                className=" mb-0"
              >
                <Input ref={invbalanceInputRef} />
              </Form.Item>
              <Form.Item
                name="previousbalance"
                label="Previous Balance F4"
                className=" mb-0"
              >
                <Input ref={prebalanceInputRef} readOnly />
              </Form.Item>
              <Form.Item
                name="newbalance"
                label="New Balance F5"
                className=" mb-0"
              >
                <Input ref={newbalanceInputRef} readOnly />
              </Form.Item>
            </div>
            <div className=" grid sm:grid-cols-4 gap-1 sm:col-span-4">
              <Form.Item
                name="invnum"
                label="Invoice Number F7"
                className=" mb-0"
              >
                <Input ref={invnumInputRef} />
              </Form.Item>
              <Form.Item label=" ">
                <Button
                  type="primary"
                  className="w-full"
                  onClick={() => {
                    handlereport();
                  }}
                >
                  Get Report
                </Button>
              </Form.Item>
              <Form.Item label=" ">
                <Button
                  type="primary"
                  className="w-full"
                  onClick={() => {
                    setShowfinishModal(true);
                  }}
                >
                  Finish
                </Button>
              </Form.Item>
              <Form.Item label=" ">
                <Button type="primary" htmlType="submit" ref={savebuttonRef}>
                  Save Purchase Return
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
        <Modal
          title="Purchase Return Report"
          open={showPdfModal}
          onCancel={() => setShowPdfModal(false)}
          centered
          width={1000}
          destroyOnClose
          footer={[
            <Button key="1" onClick={() => setShowPdfModal(false)}>
              Cancel
            </Button>,
          ]}
        >
          <iframe
            width="100%"
            height="500px"
            src={reportData}
            title="Finance Report"
          ></iframe>
        </Modal>
        <Modal
          title="Purchase Return Finish List"
          open={showfinishModal}
          onCancel={() => setShowfinishModal(false)}
          centered
          width={1000}
          destroyOnClose
          footer={[
            <Button key="1" onClick={() => setShowfinishModal(false)}>
              Cancel
            </Button>,
          ]}
        >
          <PurchaseReturnFinishList />
        </Modal>
      </div>
    </section>
  );
};
